<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up" v-if="$store.state.User.jibieId==0"><span>门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName">
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span>进账标题：</span><input type="text" v-model="form.itemName"></li>
            <li class="up"><span>是否入账：</span>
              <select v-model="form.isInBill">
                <option :value="1">不入账</option>
                <option v-for="item in zffsList" :key="item.id" :value="item.id">{{item.name}}</option>
              </select>
            </li>
            <li class="up"><span>进账类型：</span>
              <select v-model="form.leixing">
                <option :value="1" >商品退货</option>
                <option :value="2" >其他进账</option>
              </select>
            </li>
            <li class="up"><span>进账金额：</span><input type="number" v-model="form.shifukuan"></li>
            <template v-if="!MmsData.new">
              <li class="up"><span>状态：</span>{{form.state==1?'正常':form.state==3?'部分撤销':'撤销'}}</li>
              <li class="up"><span>记录时间：</span>{{getDateTime(form.caozuoshijian)}} </li>
              <li class="up"><span>操作人：</span>{{form.caozuorenName}}</li>
            </template>
            <template v-if="!MmsData.new&&form.resetId">
              <li class="up"><span>撤销时间：</span>{{getDateTime(form.resetTime)}}</li>
              <li class="up"><span>撤销人：</span>{{form.resetName}}</li>
              <li class="up"><span>撤销金额：</span>{{form.tuikuanjine}}</li>
            </template>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" v-if="!MmsData.new&&!form.resetId&&form.state==1&&form.leixing!=1" @click="delOneData(form.id,form.itemName)" v-pow:6202>撤销</button>
            <button class="settab" v-if="MmsData.new" @click="subData">确定</button>
            <button class="remole" @click="closedWindow">关闭</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {testStr,deepCopy} from '@/utils/util'
import moment from 'moment'
import {zffsList} from '@/data/data'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      zffsList,
      storeList:[],
      dataList:[],
      form:{
        fenpeimendianId: this.$store.state.User.fenpeimendianId,
        info: "",
        isInBill: 1,
        itemName: "",
        leixing: 2,
        shifukuan: null,
      },
      defform:{
        fenpeimendianId: this.$store.state.User.fenpeimendianId,
        info: "",
        isInBill: 1,
        itemName: "",
        leixing: 2,
        shifukuan: null,
      }
    }
  },
  created() {this.init()},
  components: {},
  mounted() {
  },
  methods: {
    delOneData(id,name){
      this.$trueOrFalse({
        title: '撤销提示',
        content: `确认撤销 ${name} （商品采购请去商品管理-采购记录撤销）这条支出?`,
        torf: false,
        suc:()=>{
          axios.post('/dataCount/delRCJZ',{id}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.$parent.getData(1)
              this.closedWindow()
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getDateTime(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    init(){
      if(this.$store.state.User.jibieId==0) this.getStore()
      if(!this.MmsData.new){
        this.form=deepCopy(this.MmsData.data)
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.itemName)){
        this.$Mesg({
          content: '进账标题不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.shifukuan)){
        this.$Mesg({
          content: '请正确填写进账金额！',
          errOrSuc: 'war',
        })
        return
      }
      axios.post('/dataCount/JZAdd',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({
            content: '新增进账成功！',
          })
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({
            content: '链接失败！',
            errOrSuc: 'err',
          })
        }
      }).catch(() => {
        this.$Mesg({
          content: '链接失败！',
          errOrSuc: 'err',
        })
      })
    },
  },
}
</script>
