<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select v-model="form.fenpeimendianId" @change="getData(1)">
            <option :value="0">全部门店</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>支出类型：</p>
          <select v-model="form.leixing" @change="getData(1)">
            <option :value="0">全部</option>
            <option :value="1">商品退货</option>
            <option :value="2">其他进账</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>编号</td>
            <td v-if="$store.state.User.jibieId==0">门店</td>
            <td>时间</td>
            <td>进账标题</td>
            <td>入账情况</td>
            <td>进账类型</td>
            <td>进账金额</td>
            <td>状态</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.id}}</td>
            <td v-if="$store.state.User.jibieId==0">{{item.fenpeimendianName}}</td>
            <td>{{getDateTime(item.caozuoshijian)}}</td>
            <td>{{item.itemName}}</td>
            <td>{{getInBill(item.isInBill)}}</td>
            <td>{{item.leixing==1?'商品退货':'其他进账'}}</td>
            <td>{{item.shifukuan}}</td>
            <td><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)">管理</button>
                <button v-if="item.leixing!=1&&item.state==1" type="button" class="err" @click="delOneData(item.id,item.itemName)" v-pow:6202>撤销</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newObjAdd" v-pow:6201>新增进账</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import moment from 'moment'
import {zffsList} from '@/data/data'
export default {
  name: 'dailyPay',
  data() {
    return {
      zffsList,
      storeList:[],
      title:'',
      infoShow: false,
      dataList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        leixing:0,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    if(this.$store.state.User.jibieId==0) this.getStore()
    else this.getData()
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    getInBill(bill){
      if(bill==1) return '不入账'
      let inbill=this.zffsList.find(e=>e.id==bill)
      if(inbill) return inbill.name
      else return ''
    },
    getDateTime(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '撤销提示',
        content: `确认撤销 ${name} （商品退货请去商品管理-退货记录撤销）这条支出?`,
        torf: false,
        suc:()=>{
          axios.post('/dataCount/delRCJZ',{id}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.itemName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else {
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }else if(lb==3){
        return '部分撤销'
      }
      else{
        return '撤销'
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/dataCount/JZlistPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          if(this.$store.state.User.jibieId==0){
            res.data.forEach(e => {
              let store=this.storeList.find(a=>a.id==e.fenpeimendianId)
              if(store) e.fenpeimendianName=store.storeName
              else e.fenpeimendianName="门店已删"
            });
          }
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newObjAdd(){
      this.title="新增进账"
      this.MmsData.new=true
      this.infoShow=true
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.getData()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
